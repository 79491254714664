<template>
  <img :src="img_url" style="width:100%;" />
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  export default {

    data() {
      return {
        img_url: ''
      };
    },
    mounted() {
      // let url = location.href;
      // this.img_url = decodeURI(getQueryVariable(url,
      //   "img_url"));
      this.getWxConfig(); //加载js
      this.init();
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      async init() {
        //获取分享二维码
        var that = this;
        var uri = this.$global.wpturl + "/tools/postvb.ashx";
        var param = {
          act: "get_qr_code",
          openid: Cookies.get("openid"), //'oAes06rjIS3yzUxani7whyFxEJLE', //
          accountId: '643',
          token: Cookies.get("token"),
          sitenum: '18777777777'
        };
        console.log(param);
        let res = await RequestApi.post(uri, param);
        console.log(res);
        if (res.status == 1) {
          Toast(res.msg);
          this.img_url = res.url

        } else {
          Toast(res.msg);

        }
      }

    },
    computed: {},
    watch: {},
  };
</script>

<style>
</style>
